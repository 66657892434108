<template>
  <div>
    <div class="config-container">
      <p>配置转换器：迷你冒险者</p>
      <div class="config-item">
        <input type="file" id="config" name="config" onclick="this.value=null;"
               @change="convertConfig">
      </div>
    </div>
  </div>
</template>

<script>
const ExcelJS = require('exceljs');

export default {
  name: 'TinyAdventure',
  data: () => ({
    publicPath: process.env.BASE_URL,
  }),
  created() {},
  methods: {
    // Excel 转 JSON
    convertConfig(event) {
      const file = event.target.files[0];
      if (file.name.search(/.xlsx$/) < 0 && file.name.search(/.xls$/) < 0) {
        this.$tip.fire({
          icon: 'error',
          title: `不支持的文件格式: ${file.name}`,
        });
        return false;
      }

      const reader = new FileReader();
      reader.onloadend = () => {
        const arrayBuffer = reader.result;
        const workbook = new ExcelJS.Workbook();
        workbook.xlsx.load(arrayBuffer).then((excel) => {
          // 配置项转化
          let count = 0;
          const configSheet = excel.worksheets[0];
          let result = '{';
          result += this.keyToValue(configSheet, 'config');
          result += '\n}';
          console.info(result);
          result = JSON.parse(result);

          // 怪物
          const monsterSheet = excel.worksheets[1];
          count = 0;
          monsterSheet.eachRow((row, rowNumber) => {
            if (rowNumber === 1) {
              result.monster = [];
              return;
            }

            let i = 1;
            result.monster[count] = {};
            result.monster[count].id = this.getText(row.values[i++]);
            result.monster[count].level = this.getText(row.values[i++]);
            result.monster[count].exp = this.getText(row.values[i++]);
            i++; // 怪物点数：无需记录
            result.monster[count].name = this.getText(row.values[i++]);
            result.monster[count].attack_max = this.getText(row.values[i++]);
            result.monster[count].attack_min = this.getText(row.values[i++]);
            result.monster[count].hp = this.getText(row.values[i++]);
            result.monster[count].defence = this.getText(row.values[i++]);
            result.monster[count].vision = this.getText(row.values[i++]);
            result.monster[count].nimble = this.getText(row.values[i++]);
            result.monster[count].flops = [];
            count++;
          });
          console.info('怪物执行完毕');
          console.info(count);
          console.info(result.monster);

          // 怪物掉落
          const monsterFlopSheet = excel.worksheets[2];
          count = 0;
          monsterFlopSheet.eachRow((row, rowNumber) => {
            if (rowNumber === 1) {
              return;
            }

            let i = 1;
            count = this.getText(row.values[i++]) - 1; // monster-id to index
            console.info('怪物掉落执行完毕');
            console.info(count);
            console.info(result.monster);
            result.monster[count].flops.push({
              sku: this.getText(row.values[i++]),
              rate: this.getText(row.values[i++]),
              min: this.getText(row.values[i++]),
              max: this.getText(row.values[i++]),
            });
          });

          console.info(result);
          result = JSON.stringify(result);

          // 创建隐藏的可下载链接
          const eleLink = document.createElement('a');
          eleLink.download = 'config.json';
          eleLink.style.display = 'none';
          // 字符内容转变成blob地址
          const blob = new Blob([result]);
          eleLink.href = URL.createObjectURL(blob);
          // 触发点击并移除
          document.body.appendChild(eleLink);
          eleLink.click();
          document.body.removeChild(eleLink);
        });
      };
      reader.readAsArrayBuffer(file);
      return true;
    },

    // 获取实际 Text
    getText(cell) {
      if (cell === undefined) {
        return '';
      }
      let text = '';
      if (cell.result) {
        return cell.result;
      }
      if (cell.richText) {
        cell.richText.map((value) => {
          if (value.text === '\n' || value.text === '\r' || value.text === '\r\n') {
            text += '\\n';
          } else {
            text += value.text.replaceAll('\n', '\\n');
          }
          return value;
        });
        return text;
      }
      if (typeof cell === 'string') {
        return cell.replaceAll('\n', '\\n');
      }
      return cell;
    },

    // excel k:v 转化器
    keyToValue(configSheet, parentName) {
      let result = '';
      configSheet.eachRow((row, rowNumber) => {
        if (rowNumber === 1) {
          result += `\n"${parentName}": {`;
        }

        let i = 1;
        console.info(row.values);
        result += `"${this.getText(row.values[i++])}": "${this.getText(row.values[i++])}"`;
        if (configSheet.getRow(rowNumber + 1).values.length) result += ', ';
        else result += '}';
      });

      return result;
    },
  },
};
</script>

<style lang="scss" scoped>
.config-container {
  display: flex;
  justify-content: flex-start;
}
.config-item {
  flex: 0 1 20%;
  a {
    color: cornflowerblue;
    text-decoration: underline;
  }
  a:hover {
    color: cornflowerblue;
    text-decoration: none;
  }
}

.board-container {
  width: 470px;
  height: 470px; /* height given for illustration */
  display: flex;
  flex-flow: row wrap;
  position: relative;
}

.board-item {
  text-align: center;
  font-size: 0.8rem;
  border: 1px solid black;
  margin: 4px;
  flex: 0 1 calc(20% - 10px);
}

a {
  color: #000000;
  text-decoration: none;
}
a:hover {
  color: #000000;
  text-decoration: none;
}

/* 模态框 */
.overlay {
  visibility: hidden;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, .7);
  .cancel {
    position: absolute;
    width: 100%;
    height: 100%;
  }
  .modal {
    position: relative;
    width: 600px;
    max-width: 80%;
    background: white;
    border-radius: 8px;
    padding: 1em 2em;
    .modal-close {
      position: absolute;
      top: 10px;
      right: 15px;
      color: grey;
      text-decoration: none;
      font-size: 1rem;
    }
  }
}
.overlay:target {
  visibility: visible;
}
</style>
